<template>
  <v-container ref="content" fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <span class="display-1 text--primary d-flex align-center align-content-center">
          {{ currentReport.title }}
          <v-spacer />
          <v-btn text color="white" @click="returnToQrView()" :disabled="searchRecords" :loading="searchRecords">
            <v-icon left color="black">
              mdi-arrow-left
            </v-icon>
            <strong class="black--text">{{ $t('general.buttons.back') }}</strong>
          </v-btn>
        </span>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      checkbox-color="primary"
      v-model="selectedItems"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
      :server-items-length="totalFromServer"
      :headers="headers"
      hide-default-header
      :items="filterdsRecords"
      :loading="(searchRecords || loadingTraceability)"
      :loading-text="$t('general.notification.loadData')"
      :no-data-text="$t('general.ui.not_data_found')"
      :options.sync="options"
      :show-select="showCheckBox"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center">
          <v-col lg="5" md="5" sm="12" xl="5" class="d-flex flex-row justify-space-around" v-if="!isShow">
            <v-row justify="space-between" align="center">
              <v-col :cols="4" class="mr-0 pr-0">
                <a-select-lots
                  v-if="!isShow"
                  class="mr-1"
                  :refer="`lots`"
                  @data:change="getProducts($event)"
                  :multiple="false"
                  :field-name="$t('reports.fields.lot')"
                  :show-selected="false"
                  :clearable="true"
                />
              </v-col>
              <v-col :cols="4" class="mx-0 px-0">
                <v-autocomplete
                  flat
                  v-if="!isShow"
                  :no-data-text="$t('general.fields.noDataAvailable')"
                  persistent-hint
                  small
                  outlined
                  dense
                  ref="productFilters"
                  clearable
                  item-color="primary"
                  :items="products"
                  v-model="product"
                  small-chips
                  hide-details
                  color="primary"
                  :label="$t('records.label.titles.product')"
                  :loading="loadingProducts"
                  :disabled="!(products && products.length > 0) || !(lote && lote.length > 0) || isShow"
                  @click:clear="clearProductData"
                >
                  <template v-slot:label>
                    {{$t('records.label.titles.product')}} <span class="red--text"><strong>*</strong></span>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip small>
                      <span>{{ item | truncate(10) }}</span>
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2" class="ml-0 pl-0" v-if="!isShow">
                <!-- Existing Report Dialog-->
                <v-dialog
                  v-model="isExist"
                  persistent
                  max-width="420"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-1 t-bw-primary--text"
                      color="primary"
                      :elevation="0"
                      height="40"
                      :disabled="!(lote && product)"
                      :loading="loadingSearch"
                      @click="filteredByLote()"
                    >
                      <v-icon class="mr-1" color="t-bw-primary"
                      >mdi-magnify</v-icon
                      >
                      <template v-slot:loader>
                        <v-progress-circular
                          style="position: absolute;
                                                   margin-left: -3px;
                                                   height: 15px;"
                          color="secondary accent-4"
                          indeterminate
                          rounded
                        />
                      </template>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline secondary t-bw-secondary--text">
                      {{ $t("reports.titles.existingReportTitle") }}
                    </v-card-title>
                    <v-card-text class="pa-6">
                      {{ $t("reports.titles.existingReportText") }}
                      <div v-if='existingReport' class='my-2'>
                        <p class='ma-0 pa-0'><strong>{{ $t("reports.fields.date") }}:</strong> {{ getDateFormat(existingReport.reportDate) }}</p>
                        <p class='ma-0 pa-0'><strong>{{ $t("reports.fields.lot") }}:</strong> {{ existingReport.lote }}</p>
                        <p class='ma-0 pa-0'><strong>{{ $t("reports.fields.product") }}:</strong> {{ existingReport.product }}</p>
                      </div>
                    </v-card-text>
                    <v-card-actions class="pa-6">
                      <v-spacer></v-spacer>
                      <v-btn
                        small
                        color="neuter"
                        text
                        @click="ifExistAction('VIEW')"
                      >
                        {{ $t("general.buttons.view") }}
                      </v-btn>
                      <v-btn
                        small
                        color="primary"
                        elevation="0"
                        class="t-bw-primary--text"
                        @click="ifExistAction('CREATE')"
                      >
                        {{ $t("general.buttons.generate") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="2" class="ml-0 pl-0" v-if="!isShow">
                <v-btn
                  :disabled="
                !!(source && source.token) || !filterdsRecords.length > 0
              "
                  :elevation="0"
                  class="mr-1"
                  color="gray"
                  small
                  style="border-radius: 1.5em;"
                  tile
                  @click="checkBoxShow"
                >
              <span v-if="!showCheckBox">
                <v-icon color="neuter" left small
                >mdi-checkbox-multiple-marked-outline</v-icon
                >
                <span>{{ $t('general.buttons.checksBoxEnable') }}</span>
              </span>
                  <span v-else>
                <v-icon color="neuter" left small
                >mdi-checkbox-multiple-marked</v-icon
                >
                <span>{{ $t('general.buttons.checksBoxDisable') }}</span>
              </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="d-flex justify-start align-center" v-else>
            <span class="d-flex justify-start mr-2">
                  <strong class="pa-2" style="
                    border: 1px solid lightgrey;
                    border-radius: 0.3em 0 0 0.3em !important;
                    height: 32px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    align-items: center;
                    width: auto;
                  ">
                    {{$t('reports.fields.lot')}}
                  </strong>
                  <v-chip style="border-radius: 0 .3em .3em 0 !important;">
                    <span class="px-2">{{ lote }}</span>
                  </v-chip>
                </span>
            <span class="d-flex justify-start mr-6">
                  <strong class="pa-2" style="
                    border: 1px solid lightgrey;
                    border-radius: 0.3em 0 0 0.3em !important;
                    height: 32px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-content: center;
                    align-items: center;
                    width: auto;
                  ">
                    {{$t('records.label.titles.product')}}
                  </strong>
                  <v-chip style="border-radius: 0 .3em .3em 0 !important;">
                    <span>{{ product }}</span>
                  </v-chip>
                </span>
            <v-btn
              :disabled="
                !!(source && source.token) || !filterdsRecords.length > 0
              "
              :elevation="0"
              class="mr-1"
              color="gray"
              small
              style="border-radius: 1.5em;"
              tile
              @click="checkBoxShow"
            >
              <span v-if="!showCheckBox">
                <v-icon color="neuter" left small
                >mdi-checkbox-multiple-marked-outline</v-icon
                >
                <span>{{ $t('general.buttons.checksBoxEnable') }}</span>
              </span>
              <span v-else>
                <v-icon color="neuter" left small
                >mdi-checkbox-multiple-marked</v-icon
                >
                <span>{{ $t('general.buttons.checksBoxDisable') }}</span>
              </span>
            </v-btn>
          </v-col>

          <v-col lg="5" md="5" sm="6" xl="5">
            <v-toolbar color="white" flat>
              <v-spacer></v-spacer>
<!--              <v-btn
                :disabled="
                  !!(source && source.token) || isShow
                "
                id="change"
                :elevation="0"
                color="gray"
                small
                style="border-radius: 1.5em 0 0 1.5em;"
                tile
                @mouseenter="isHover = true"
                @mouseleave="isHover = false"
                @click="changeReport()"
              >
                <p :style="(currentReport.type === 'rastreability' && isHover) ? 'width: 60px' : ''" class="ma-0 pa-0">{{ (currentReport.type === 'recall' ? $t('reports.titles.traceability') : $t('reports.titles.recall')) }}</p>
                <v-icon color="neuter">mdi-swap-horizontal</v-icon>
              </v-btn>
              <v-badge
                :content="cantFilters"
                :value="cantFilters"
                bordered
                color="neuter"
                left
                overlap
              >
                <v-btn
                  :disabled="!(filterdsRecords.length > 0)"
                  :elevation="0"
                  class="ma-1"
                  color="gray"
                  small
                  style="border-radius: 0;"
                  tile
                  @click="openFilters"
                >
                  <v-icon color="neuter" left small>mdi-filter-variant</v-icon>
                  {{ $t('general.buttons.filter') }}
                </v-btn>
              </v-badge>
              <v-menu
                :absolute="false"
                :open-on-hover="false"
                :close-on-click="true"
                :close-on-content-click="false"
                :offset-y="true"
                v-model="menu"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="gray"
                    class="mr-1"
                    @click="menu = !menu"
                    :disabled='!!(source && source.token) || isShow'
                  >
                    <v-icon small color="neuter" left>mdi-cog</v-icon>
                    <span>{{ $t('general.buttons.options') }}</span>
                  </v-btn>
                </template>
                <v-list class="pa-3">
                  <v-list-item
                    dense
                    class="d-block py-2"
                  >
                    <div class="d-block">
                      &lt;!&ndash; Generate Multiple Reports&ndash;&gt;
                      <GenerateNewTraceabilityReportTable v-on:closeMenu="(menu = $event)" :reportType="currentReport.type" />
                    </div>
                  </v-list-item>

                </v-list>
              </v-menu>
              <v-btn
                :disabled="
                  !(filterdsRecords.length > 0) ||
                    !(filterdsRecordsBackUp.length > 0)
                "
                :elevation="0"
                color="gray"
                small
                style="border-radius: 0 1.5em 1.5em 0;"
                tile
                @click="exportDialog"
              >
                <v-icon color="neuter" left small
                >mdi-download-multiple
                </v-icon
                >
                {{ $t('reports.buttons.traceability') }}
              </v-btn>-->
              <button-group-component :buttons="buttonsConfig">
                <template v-slot:menu-content-2="{ closeMenu }">
                  <v-list-item dense class="d-block py-2">
                    <div class="d-block">
                      <GenerateNewTraceabilityReportTable
                        @closeMenu="closeMenu"
                        :reportType="currentReport.type"
                      />
                    </div>
                  </v-list-item>
                </template>
              </button-group-component>

            </v-toolbar>
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          class="mb-3"
          outlined
          elevation="0"
          style="border-radius: 0.3em"
          v-if="filtersChips && filtersChips.length > 0"
        >
          <v-row style="display: contents">
            <v-col class="d-flex" cols="12">
              <v-chip-group show-arrows>
                <v-chip
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  :disabled="!!(source && source.token)"
                  close
                  small
                  @click:close="cleanFilter(chip)"
                >
                  <v-icon left color="t-adj-primary darken-3" small>mdi-filter-variant</v-icon>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </template>

      <template v-slot:no-data>
        <div class="d-flex justify-center align-center">
          {{ $t('general.ui.not_data_found') }}
        </div>
      </template>

      <template v-slot:no-results>
        <div class="d-flex justify-center align-center">
          {{ $t('general.ui.not_search_found') }}
        </div>
      </template>

      <template v-slot:loading>
        {{ $t('general.notification.loadData') }}
      </template>

      <template v-slot:header="{ props }">
        <thead class="table__head">
          <th v-for="header in props.headers" :style="`max-width: 100%; width: ${header.width}px`"
            class="text-start px-4">
            <span v-if="header.value === 'action'" class="d-flex justify-center">
              {{ $t(header.text) }}
              <UserOptions :module="'traceability'" :type="'TABLE'"/>
            </span>
            <span v-else>
              <span v-if="['record_date_time', 'sent_date_time', 'uniqueIdRepresentation'].includes(header.value)">
                {{ $t(header.text) }}
                <v-btn icon small @click="toggleSort(header.value)">
                  <v-icon small>{{ getSortIcon(header.value) }}</v-icon>
                </v-btn>
              </span>
              <span v-else>
                {{ $t(header.text) }}
              </span>
            </span>
          </th>
        </thead>
      </template>

      <template v-slot:body="{ items }">
        <v-progress-linear
          v-if="searchRecords"
          color="primary"
          indeterminate
          style="visibility: hidden"
        />
        <tr>
          <td v-if="searchRecords" :colspan="headers.length">
            <div class="d-flex justify-center align-center mt-2">
              {{ $t('general.notification.loadData') }}
            </div>
          </td>

          <td
            v-if="
              !(filterdsRecords && filterdsRecords.length > 0) && !searchRecords
            "
            :colspan="headers.length"
          >
            <div class="d-flex justify-center align-center mt-2">
              {{ $t('general.ui.not_data_found') }}
            </div>
          </td>
        </tr>
        <tr v-for="item in items" style="height: 50px">
          <td v-if="showCheckBox">
            <v-checkbox
              v-model="selectedItems"
              :value="item"
              class="ma-0 pa-0"
              color="primary"
              hide-details
            />
          </td>
          <td v-for="header in headers" class="px-0">
            <div v-if="header.value === 'statusLine'">
              <div class="dot-validated" v-if="item.status === 'VALIDATED'">
              </div>
              <div class="dot-pending" v-if="item.status === 'PENDING'">
              </div>
              <div class="dot-validating" v-if="item.status === 'VALIDATING'">
              </div>
              <div class="dot-errored" v-if="item.status === 'ERRORED'">
              </div>
            </div>
            <div v-if="header.value === 'status'">
              <v-chip small v-if="item.status === 'VALIDATED'">{{ $t('records.filters.status.validated') }}</v-chip>
              <v-chip small v-if="item.status === 'PENDING'">{{ $t('records.filters.status.pending') }}</v-chip>
              <v-chip small v-if="item.status === 'VALIDATING'">{{ $t('records.filters.status.validating') }}</v-chip>
              <v-chip small v-if="item.status === 'ERRORED'">{{ $t('records.filters.status.errored') }}</v-chip>
            </div>
            <div v-if="header.value === 'uniqueIdRepresentation'">
              <UniqueIdRepresentationComponent :unique-id="item.uniqueIdRepresentation" />
            </div>
            <div v-if="header.value === 'sdi_origin_name'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small>{{
                      (item.sdi_origin.primaryLocation.name +
                        ' | ' +
                        translate(item.sdi_origin.control_label))
                        | truncate(20)
                    }}
                  </v-chip>
                </template>
                <span style="font-size: 12px; color: #f5f5f5">
                  {{
                    item.sdi_origin.primaryLocation.name +
                    ' | ' +
                    translate(item.sdi_origin.control_label)
                  }}
                </span>
              </v-tooltip>
            </div>
            <div v-else-if="header.value === 'sdi_destination_name'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small>{{
                      (item.sdi_destination.primaryLocation.name +
                        ' | ' +
                        translate(item.sdi_destination.control_label))
                        | truncate(20)
                    }}
                  </v-chip>
                </template>
                <span style="font-size: 12px; color: #f5f5f5">
                  {{
                    item.sdi_destination.primaryLocation.name +
                    ' | ' +
                    translate(item.sdi_destination.control_label)
                  }}
                </span>
              </v-tooltip>
            </div>
            <div v-else-if="header.value === 'sdt_to_show_translate'">
              <v-chip small>{{
                  translate(item.process.sdt_to_show.language_key)
                }}
              </v-chip>
            </div>
            <div v-else-if="header.value === 'record_date_time'" style='width: 150px !important;'>
              <span>{{ getDateFormat(item.record_date_time) }}</span>
            </div>
            <div v-else-if="header.value === 'process_name'">
              <v-chip class="ma-1" small>{{
                  translate(item.process.language_key)
                }}
              </v-chip>
            </div>
            <div v-else-if="header.value === 'person_in_charge_name'">
              <v-chip class="ma-1" small>{{
                  item.person_in_charge.name
                }}
              </v-chip>
            </div>
            <div
              v-else-if="header.value === 'action'"
              class="d-flex justify-center align-center"
            >
              <MoreDetailsComponent :item="item" :disabled="allProccessLoading" @on:load="loadingTraceability = $event" />

            </div>
            <div v-for="column in item.extraColumns">
              <div v-if="header.value === translate(column.language_key)">
                <v-tooltip max-width="350" top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{
                        column.recordValues | truncate(15)
                      }}</span>
                  </template>
                  <span style="font-size: 12px; color: #f5f5f5"
                  >{{ column.recordValues }}
                  </span>
                </v-tooltip>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-snackbar v-model="request" :timeout="-1">
      <span>
        {{ message }}
        <v-progress-circular v-if="source"
                             class="ml-2"
                             indeterminate
                             size="20"
                             width="4"
        />
      </span>

      <v-btn v-if="source !== null" color="red" text @click="cancelRequest">
        <v-icon color="red" left small>
          mdi-hand-back-left
        </v-icon>
        {{ $t('general.buttons.cancel') }}
      </v-btn>
      <v-btn v-else color="primary" text @click="refreshRequest">
        <v-icon color="primary" left small>
          mdi-reload
        </v-icon>
        {{ $t('general.buttons.refresh') }}
      </v-btn>
    </v-snackbar>
    <!-- Details Export-->
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline secondary t-bw-secondary--text">{{
              $t('reports.titles.exportConfigPDF')
            }}
          </v-card-title>
          <v-card-text class="pa-6">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  dense
                  v-model="orientation"
                  :items="pageEnum"
                  :label="$t('reports.titles.orientation')"
                  :no-data-text="$t('general.fields.noDataAvailable')"
                  color="primary"
                  item-color="primary"
                  item-text="text"
                  item-value="type"
                  small-chips
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center" cols="6">
                <v-switch inset v-model="evidencePresent" color="primary">
                  <template v-slot:prepend>
                    <v-label ref="label"
                    >{{ $t('reports.titles.evidences') }}
                    </v-label>
                  </template>
                </v-switch>
              </v-col>
              <v-col cols="6">
                <v-switch inset v-model="documentPresent" color="primary">
                  <template v-slot:prepend>
                    <v-label ref="label"
                    >{{ $t('reports.titles.documents') }}
                    </v-label>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pa-6">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="exportLoading"
              color="neuter"
              small
              text
              @click="dialogClose"
            >
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn
              :disabled="!orientation"
              :loading="exportLoading"
              color="primary"
              elevation="0"
              class="t-bw-primary--text"
              small
              @click="exportRecords"
            >
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import i18n from '@/plugins/i18n'
  import {mapActions, mapGetters} from 'vuex'
  import moment from 'moment'
  import _ from 'lodash'
  import 'jspdf-autotable'
  import { checkSameType } from '@/utils'
  import {generalMixin} from '@/mixins/general.mixin'
  import DocumentsService from '@/services/DocumentsService'
  import LanguajeService from '@/services/LanguajeService'
  import UserOptions from '@/components/UserOptions/IndexComponent.vue'
  import sortList from "@/services/OrderListService";
  import GenerateNewTraceabilityReportTable from '@/module/reports/components/GenerateNewTraceabilityReportTable.vue'
  import ASelectLots from "@/components/common/AAutocomplete/ASelectLots.vue";
  import UniqueIdRepresentationComponent from "@/module/records/components/UniqueIdRepresentationComponent.vue";
  import MoreDetailsComponent from "@/module/records/components/MoreDetailsComponent.vue"
  import ButtonGroupComponent from "@/components/common/ButtonGroup/ButtonGroupComponent.vue"

  const FILTER_VALUES = [
    'company_id',
    'min_punctuation',
    'max_punctuation',
    'surveyStateType',
    'template_names',
    'pageableDTO',
    ' start_record_date_time',
    'end_record_date_time',
    'storage_device_ids',
    'document_authority_ids',
    'document_type_ids',
    'document_description_ids',
    'document_date',
    'expiration_date',
    'storage_devices_ids',
    'storage_device_names',
    'storage_device_names_list',
    'structure',
    'lots',
    'products_ids',
    'record_ids',
    'sellers_names',
    'contracts_names',
    'volumeB_range',
    'volumeN_range',
    'quebra_range',
    'contracts_dates',
    'withExternalVisibility',
    'active',
    'reportType'
  ]

  export default {
    components: {
      ButtonGroupComponent,
      MoreDetailsComponent,
      UniqueIdRepresentationComponent, ASelectLots, GenerateNewTraceabilityReportTable, UserOptions},
    mixins: [generalMixin],

    props: {
      item: {
        type: Object
      }
    },

    data: () => ({
      searchRecords: false,
      //portrait
      //landscape
      logoPositionX: 0,
      doc: '',
      evidenceListUrl: [],
      documentListUrl: [],
      indexEvidence: 0,
      indexDocument: 0,
      imgDataList: [],
      importDialog: false,
      sortBy: 'record_date_time',
      sortDesc: false,
      columnSorting: {},
      profile: null,
      dialogDetails: false,
      // FILTERs
      lote: null,
      product: null,
      period: [],
      filtersDate: [],
      filterdsRecords: [],
      filterdsRecordsBackUp: [],
      filterdsRecordsIdChecked: [],
      LotsOuts: [],
      menu: false,
      show: false,
      fieldsFilters: [],
      structureFilter: [],
      refers: {
        record_date_time: '',
      },
      showDialog: false,
      // config
      orientation: null,
      documentPresent: true,
      evidencePresent: true,
      exportLoading: false,
      message: i18n.t('general.notification.loadData'),
      selectedItems: [],
      showCheckBox: false,
      options: {},
      loadingProducts: false,
      loadingSearch: false,
      isShow: false,
      checkboxState: {
        products: false,
        lots: false,
      },
      defaultPageableDTO: {
        page:0,
        size:10,
        sortBy: "id",
        direction: "desc"
      },
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200, 500],
      },
      reports: [{
        type: 'rastreability',
        title: i18n.t('reports.titles.traceability-report')
      }, {
        type: 'recall',
        title: i18n.t('reports.titles.recall-report')
      }],
      currentReport: {
        type: 'rastreability',
        title: i18n.t('reports.titles.traceability-report')
      },
      isHover: false,
      isExist: false,
      ifExistFilters: null,
      existingReport: null,
      loadingEvidences: false
    }),

    watch: {
      options: {
        async handler() {
          this.optionsTable = this.options
          const direction =
            this.options.sortDesc.length === 0 || this.options.sortDesc[0]
              ? 'desc'
              : 'asc'

          this.filters.pageableDTO = {
            page: this.options.page > 0 ? this.options.page - 1 : 0,
            size: this.options.itemsPerPage > -1 ? this.options.itemsPerPage : this.totalFromServer,
            sortBy: 'record_date_time',
            direction: direction,
          }

          await this.fetchTraceabilityById()
        },
        deep: true,
      },
    },

    async mounted() {
      this.type = 'traceability'
      this.$store.commit('ui/FILTERS_ORIGINAL_STATE')

      this.$root.$on('setFiltersTraceability', (page) => {
        this.closeFilterDialog(page)
      })

      this.$root.$on('getResults', async () => {
        await this.filteredByLote()
      })

      if (this.item) {
        if (this.item.reportType === 'TRACEABILITY') {
          this.currentReport = this.reports[0];
        }

        if (this.item.reportType === 'RECALL') {
          this.currentReport = this.reports[1];
        }

        this.filters.reportType = this.item.reportType;
        this.filters.generateNew = false;

        this.ifExistFilters = {
          reportType: this.item.reportType
        }

        this.lote = this.item.lot;
        this.product = this.item.product;

        this.filters.pageableDTO = _.cloneDeep(this.defaultPageableDTO)

        this.isShow = true

        await this.filteredByLote().finally(() => {
          this.ifExistAction('VIEW')
        })
      }
    },

    computed: {
      ...mapGetters({
        documents: 'documents/documentsTraceabilityReport',
        loadingDocuments: 'documents/loading',
        storages: 'documents/storages',
        storagesLoading: 'storage/loading',
        recordsToTraceabilityReport: 'records/recordsToTraceabilityReport',
        recordsIdsByLot: 'records/recordsIdsByLot',
        recordsCompanyById: 'records/recordsCompanyById',
        locationsList: 'locations/locationsList',
        requireAndMaxRules: 'general/requireAndMaxRules',
        requireRules: 'general/requireRules',
        pageEnum: 'reports/pageEnum',
        products: 'details/products',
        allProccess: 'proccess/proccess',
        allProccessLoading: 'proccess/loading',
        lots: 'details/lots',
        totalFromServer: 'reports/totalRTFromServer',
        secondaries: 'storage/GET_STORAGES_FOR_FILTERS',
        //----ui-----------------------------------------
        defaultHeaders: 'reports/defaultHeadersTraceability',
        filterHeader: 'ui/GET_HEADERS_TRACEABILITY',
      }),
      buttonsConfig() {
        return [
          {
            type: 'button',
            text: this.currentReport.type === 'recall'
              ? this.$t('reports.titles.traceability')
              : this.$t('reports.titles.recall'),
            icon: 'mdi-swap-horizontal',
            action: this.changeReport,
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
              tile: true,
              disabled: !!(this.source && this.source.token) || this.isShow,
              id: 'change',
              style: 'border-radius: 1.5em 0 0 1.5em;',
              onmouseenter: () => this.isHover = true,
              onmouseleave: () => this.isHover = false,
            },
          },
          {
            type: 'button',
            text: this.$t('general.buttons.filter'),
            icon: 'mdi-filter-variant',
            action: this.openFilters,
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
              tile: true,
              disabled: !(this.filterdsRecords.length > 0),
              style: 'border-radius: 0;',
            },
            badge: {
              content: this.cantFilters,
              value: this.cantFilters,
              color: 'neuter',
              bordered: true,
              overlap: true,
              left: true,
            },
          },
          {
            type: 'menu',
            text: this.$t('general.buttons.options'),
            icon: 'mdi-cog',
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
              tile: true,
              disabled: !!(this.source && this.source.token) || this.isShow,
            },
          },
          {
            type: 'button',
            text: this.$t('reports.buttons.traceability'),
            icon: 'mdi-download-multiple',
            action: this.exportDialog,
            props: {
              small: true,
              elevation: 0,
              color: 'gray',
              tile: true,
              style: 'border-radius: 0 1.5em 1.5em 0;',
              disabled: !(this.filterdsRecords.length > 0) || !(this.filterdsRecordsBackUp.length > 0),
            },
          },
        ];
      },
      loadingTraceability: {
        get() {
          return this.$store.getters['reports/loading'];
        },
        set(value) {
          this.$store.commit('reports/SET_LOADING', value);
        }
      },
      config: {
        get() {
          return this.$store.getters['general/config']
        },
        set(val) {
          this.$store.commit('general/SET_CONFIG', val)
        },
      },
      headers: {
        get() {
          return this.$store.getters['reports/headersTraceability']
        },
        set(val) {
          this.$store.commit('reports/SET_HEADERS_TRACEABILITY', val)
        },
      },
      source: {
        get() {
          return this.$store.getters['records/source']
        },
        set(val) {
          this.$store.commit('records/SET_SOURCE', val)
        },
      },
      request: {
        get() {
          return this.$store.getters['records/request']
        },
        set(val) {
          this.$store.commit('records/SET_REQUEST', val)
        },
      },
      drawer: {
        get() {
          return this.$store.getters['ui/GET_DRAWER']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DRAWER', val)
        },
      },
      cantFilters: {
        get() {
          return this.$store.getters['ui/GET_COUNT_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters['ui/GET_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS', val)
        },
      },
      optionsTable: {
        get() {
          return this.$store.getters['ui/GET_OPTIONS_TABLE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
        },
      },
      locations: {
        get() {
          return this.$store.getters['ui/GET_LOCATIONS_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_LOCATIONS_FILTERS', val)
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      date: {
        get() {
          return this.$store.getters['ui/GET_DATE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATE_FILTERS', val)
        },
      },
      filtersChips: {
        get() {
          return this.$store.getters['ui/GET_FILTERS_CHIPS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
        },
      },
      type: {
        get() {
          return this.$store.getters['ui/GET_FILTER_TYPE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_TYPE', val)
        },
      },
      recordSelected: {
        get() {
          return this.$store.getters['records/record']
        },
        set(val) {
          this.$store.commit('records/SET_RECORD', val)
        },
      },
      logRecordSelected: {
        get() {
          return this.$store.getters['records/recordLog']
        },
        set(val) {
          this.$store.commit('records/SET_RECORD_LOG', val)
        },
      },
      traceability: {
        get() {
          return this.$store.getters['reports/traceability']
        },
        set(val) {
          this.$store.commit('reports/SET_TRACEABILITY', val)
        },
      },
      groupStructures() {
        return this.getGroupByProcess()
      },
    },

    async created() {
      this.$store.commit('ui/SET_FILTERS_SORT', [6,8,9,13,14,36,15,16,33]);

      await this.initialize()
    },
    methods: {
      ...mapActions({
        fetchTraceabilityReportData: 'reports/fetchTraceabilityReportData',
        fetchExistingTraceabilityReportData: 'reports/fetchExistingTraceabilityReportData',
        fetchListStructures: 'records/fetchListStructures',
        exportRecordsAction: 'records/exportRecords',
        fetchListStorage: 'documents/fetchListStorage',
        fetchListDocuments: 'documents/fetchListDocuments',
        fetchListProduct: 'product/fetchListProduct',
        appointmentsByLote: 'records/appointmentsByLote',
        findRecordById: 'records/findRecordById',
        findLogRecordById: 'records/findLogByRecordId',
        fetchListStructureProducts: 'details/fetchListStructureProducts',
        // fetchListStructureLots: 'details/fetchListStructureLots'
      }),
      toggleSort(column) {
        // Check if the column was previously sorted
        if (this.columnSorting[column]) {
          // Toggle between ascending, descending, and no sort
          this.columnSorting[column] = this.columnSorting[column] === 'desc' ? 'asc' : 'desc';
        } else {
          // If the column wasn't sorted before, start with descending sort
          this.columnSorting = {}; // Reset sorting of other columns
          this.columnSorting[column] = 'desc';
        }
        // Set the main sort indicators used by v-data-table
        this.sortBy = column;
        this.sortDesc = this.columnSorting[column] === 'desc';
      },
      getSortIcon(column) {
        if (this.sortBy === column) {
          return this.sortDesc ? 'mdi-sort-descending' : 'mdi-sort-ascending';
        }
        return 'mdi-sort';
      },
      changeReport() {
        switch (this.currentReport.type) {
          case "rastreability":
            this.currentReport = this.reports[1]
            return
          case "recall":
            this.currentReport = this.reports[0]
            return
        }
      },
      async getProducts(lot) {
        if (lot && lot.name) {
          this.lote = lot.name;

          this.loadingProducts = true
          await this.fetchListStructureProducts([this.profile, lot.name, this.$toast]).finally(() => {
            this.loadingProducts = false
          })
        } else {
          this.lote = null;
          this.product = null;
        }
      },
      checkBoxShow() {
        this.showCheckBox = !this.showCheckBox

        if (!this.showCheckBox) this.selectedItems = []
      },
      cancelRequest() {
        if (this.source) this.source.cancel('cancelRequest')

        this.message = i18n.t('general.notification.operationCanceled')
        this.source = null
      },
      async refreshRequest() {
        this.message = i18n.t('general.notification.loadData')
        await this.filteredByLote()
      },
      async openFilters() {
        this.type = 'traceability'
        this.drawer = true
      },
      // FILTERS
      cleanFilter(chip) {
        let tag = chip.value
        if (this.filters[tag]) {
          if (this.filtersObjects[tag]) {
            this.filtersObjects[tag] = []
          }
          if (tag === 'stringFilter') {
            this.filters[tag] = null
            this.filtersObjects[tag] = null
          }
          if (tag === 'status') {
            this.$set(this.filtersObjects, 'record', {
              status: []
            })
            this.$set(this.filtersObjects, 'record', {
              status: []
            })
            this.filters.status = []
          }
          this.filters[tag] = []
          if (tag === 'sdi_origin_ids') {
            this.locations.origin_locations_filters = []
            this.$root.$emit("set:location:origin", {
              locations: [],
              storageDevice: []
            })
          } else if (tag === 'sdi_destination_ids') {
            this.locations.destination_locations_filters = []
            this.$root.$emit("set:location:destination", {
              locations: [],
              storageDevice: []
            })
          }
          if (
            tag === 'end_record_date_time' ||
            tag === 'start_record_date_time'
          ) {
            this.date.filtersRecordDate = []
            this.date.filtersDate = []
            this.filters['end_record_date_time'] = []
            this.filters['start_record_date_time'] = null
            this.filtersObjects.start_record_date_time = null
          }
          if (
            tag === 'sent_date_time'
          ) {
            this.date.filtersRecordRegisterDate = []
            this.date.filtersRegisterDate = []
            this.filters['sent_date_time'] = null
            this.filtersObjects.sent_date_time = null
          }
        }
        this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
      },
      applyFilters(page) {
        this.filters.stringFilter = this.filtersObjects.stringFilter && this.filtersObjects.stringFilter !== ''
          ? this.filtersObjects.stringFilter
          : null

        this.filters.record_ids =
          this.recordsIdsByLot && this.recordsIdsByLot.length > 0
            ? this.recordsIdsByLot
            : []
        this.filters.storage_device_types_ids =
          this.filtersObjects.storage_device_types_ids &&
          this.filtersObjects.storage_device_types_ids.length > 0
            ? this.getByProperty(
              this.filtersObjects.storage_device_types_ids,
              'storage_device_type_id'
            )
            : []
        this.filters.person_in_charge_ids =
          this.filtersObjects.person_in_charge_ids &&
          this.filtersObjects.person_in_charge_ids.length > 0
            ? this.getByProperty(this.filtersObjects.person_in_charge_ids, 'id')
            : []
        this.filters.process_ids =
          this.filtersObjects.process_ids &&
          this.filtersObjects.process_ids.length > 0
            ? this.getByProperty(this.filtersObjects.process_ids, 'id')
            : []
        this.filters.seasons_ids =
          this.filtersObjects.seasons_ids &&
          this.filtersObjects.seasons_ids.length > 0
            ? this.getByProperty(this.filtersObjects.seasons_ids, 'season_name')
            : []

        this.filters.sdi_destination_ids =
          this.filtersObjects.sdi_destination_ids &&
          this.filtersObjects.sdi_destination_ids.length > 0
            ? this.filtersObjects.sdi_destination_ids
            : []
        this.filters.sdi_origin_ids =
          this.filtersObjects.sdi_origin_ids &&
          this.filtersObjects.sdi_origin_ids.length > 0
            ? this.filtersObjects.sdi_origin_ids
            : []

        this.filters.status = this.filtersObjects.record.status &&
        this.filtersObjects.record.status.length > 0
          ? this.filtersObjects.record.status
          : []

        this.filters.start_record_date_time =
          this.date.filtersRecordDate.length > 0
            ? [
              moment(this.date.filtersRecordDate[0]).format('YYYY-MM-DD') +
              'T00:00:01',
              moment(this.date.filtersRecordDate[1]).format('YYYY-MM-DD') +
              'T23:59:59',
            ]
            : []

        this.filters.sent_date_time =
          this.date.filtersRecordRegisterDate.length > 0
            ? [
              moment(this.date.filtersRecordRegisterDate[0]).format(
                'YYYY-MM-DD'
              ) + 'T00:00:01',
              moment(this.date.filtersRecordRegisterDate[1]).format(
                'YYYY-MM-DD'
              ) + 'T23:59:59',
            ]
            : []

        this.fetchTraceabilityById(page)
      },

      getCantFilters() {
        let cant = 0
        _.forEach(this.filters, (value, key) => {
          if (![...FILTER_VALUES, 'generateNew', 'stringFilter'].includes(key) && (value !== null)) {
            cant +=
              key === 'end_record_date_time' ||
              key === ' start_record_date_time' ||
              key === ' sent_date_time'
                ? 1
                : value.length
          }

          cant +=
            (key === 'stringFilter' && value && value !== '')
              ? 1
              : 0
        })
        this.cantFilters = cant
      },

      filteredChips() {
        let chips = []
        _.forEach(this.filters, (value, key) => {
          if (
            ![...FILTER_VALUES, 'stringFilter'].includes(key) &&
            this.filters[key] !== null &&
            this.filters[key] !== undefined &&
            this.filters[key].length > 0
          ) {
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, {val: key})[0].text,
            })
          } else {
            if (key === 'stringFilter' && value && value !== '') {
              chips.push({
                value: key,
                name: i18n.t("general.fields.text"),
              })
            }
          }
        })

        this.filtersChips = chips
      },
      closeFilterDialog(page) {
        this.applyFilters(page)
        this.filteredChips()
        this.getCantFilters()
        this.drawer = false
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
      async initialize() {
        this.request = false
        this.source = null

        this.fieldsFilters.headers = _.filter(this.headers, header => {
          if (header.type !== 'actions') {
            return header
          }
        })
        this.profile = JSON.parse(localStorage.getItem('profile'))
      },
      returnToQrView() {
        this.lote = null
        this.product = null
        this.$store.commit('ui/FILTERS_ORIGINAL_STATE')

        this.$root.$emit("set:location:origin", {
          locations: [],
          storageDevice: []
        })

        this.$root.$emit("set:location:destination", {
          locations: [],
          storageDevice: []
        })

        this.$router.push({ name: 'traceability-view' })
      },
      getGroupByProcess() {
        let structures = []
        _.forEach(this.recordSelected.recordStructureValues, structure => {
          _.forEach(
            this.recordSelected.process.processStructureOrganized.groups,
            group => {
              _.forEach(group.rows, row => {
                _.forEach(row.values, processStructure => {
                  if (
                    processStructure.recordStructureValue.processStructureId
                      .id === structure.processStructureId.id &&
                    !_.find(structures, {
                      id:
                      processStructure.recordStructureValue.processStructureId
                        .id,
                    })
                  ) {
                    processStructure.structure.groupStructures = group
                    processStructure.structure.processStructureId =
                      processStructure.recordStructureValue.processStructureId
                    structures.push(processStructure.structure)
                  }
                })
              })
            }
          )
          // if (!!found) {
          //     structures.push(found);
          // }
        })
        return this.recordSelected.process
          ? _.groupBy(
            _.groupBy(this.recordSelected.recordStructureValues, o => {
              return !!o.groupStructures
            })[true],
            g => {
              return g.groupStructures.id
            }
          )
          : []
      },
      getEvidences() {
        this.loadingEvidences = true
        try {
          DocumentsService.getEvidencas(this.recordSelected.id).then(
            response => {
              const FileSaver = require('file-saver')
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              FileSaver.saveAs(blob, 'evidences.zip')
            }
          ).finally(() => {
            this.loadingEvidences = false
          })
        } catch (error) {
          this.$toast.info(i18n.t('records.notification.evidencesCero'), {
            icon: 'mdi-information-slab-circle',
            queueable: true
          })
        }
      },
      dialogClose() {
        this.orientation = null
        this.documentPresent = true
        this.evidencePresent = true
        this.showDialog = false
        this.exportLoading = false
        this.evidenceListUrl = []
        this.documentListUrl = []
        this.indexEvidence = 0
        this.indexDocument = 0
      },
      exportDialog() {
        this.showDialog = true
      },
      // EXPORT FUNCTIONS--------------------
      async exportRecords() {
        this.exportLoading = true

        const keys = ['filterValues', 'record_ids', 'language']
        const filters = _.pick(this.filters, keys)

        if (this.selectedItems && this.selectedItems.length > 0) {
          filters.record_ids =
            this.selectedItems && this.selectedItems.length > 0
              ? this.getByProperty(this.selectedItems, 'id')
              : []
        } else {
          const recordIds = JSON.parse(localStorage.getItem('recordIds'))
          if (recordIds && recordIds.length > 0) {
            filters.record_ids = recordIds
          }
        }

        filters.documentIds = JSON.parse(localStorage.getItem('documentIds'))

        let fields = []
        _.forEach(this.filters, (filter, key) => {
          if (
            (this.filters[key] && !(this.filters[key] instanceof Array)) ||
            (this.filters[key] &&
              this.filters[key] instanceof Array &&
              this.filters[key].length > 0) ||
            (this.filters[key] instanceof Date && this.filters[key].length > 0)
          ) {
            switch (key) {
              case 'company_id':
                fields.push({
                  key: i18n.t('modules.name.Company'),
                  value: this.profile.company_name_key,
                })
                return
              case 'lots':
                fields.push({
                  key: i18n.t('records.filters.lot'),
                  value: this.filters.lots.join(', '),
                })
                return
              case 'status':
                fields.push({
                  key: i18n.t('records.fields.status'),
                  value: this.filters.status.join(', '),
                })
                return
              case 'person_in_charge_ids':
                fields.push({
                  key: i18n.t('records.fields.person'),
                  value: this.getByProperty(
                    this.filtersObjects.person_in_charge_ids,
                    'name'
                  ).join(', '),
                })
                return
              case 'process_ids':
                fields.push({
                  key: i18n.t('records.fields.process'),
                  value: this.getByProperty(
                    this.filtersObjects.process_ids,
                    'proccess_name'
                  ).join(', '),
                })
                return
              case 'products_ids':
                fields.push({
                  key: i18n.t('records.label.titles.product'),
                  value: this.filters.products_ids.join(', '),
                })
                return
              case 'sdi_origin_ids':
                let originIds = this.filtersObjects.sdi_origin_ids;
                if (checkSameType(this.filtersObjects.sdi_origin_ids, 'number')) {
                  originIds = this.secondaries.filter((l) => { return this.filtersObjects.sdi_origin_ids.includes(l.storageDeviceId) });
                }
                fields.push({
                  key: i18n.t('records.fields.origin'),
                  value: this.getByProperty(
                    originIds,
                    'control_label'
                  ).join(', '),
                })
                return
              case 'sdi_destination_ids':
                let destinationIds = this.filtersObjects.sdi_destination_ids;
                if (checkSameType(this.filtersObjects.sdi_destination_ids, 'number')) {
                  destinationIds = this.secondaries.filter((l) => { return this.filtersObjects.sdi_destination_ids.includes(l.storageDeviceId) });
                }
                fields.push({
                  key: i18n.t('records.fields.destination'),
                  value: this.getByProperty(
                    destinationIds,
                    'control_label'
                  ).join(', '),
                })
                return
              case 'seasons_ids':
                fields.push({
                  key: i18n.t('records.fields.season'),
                  value: this.getByProperty(
                    this.filtersObjects.seasons_ids,
                    'season_name'
                  ).join(', '),
                })
                return
              case 'end_record_date_time':
                fields.push({
                  key: i18n.t('records.fields.date'),
                  value: this.date.filtersDate,
                })
                return
              case 'storage_device_types_ids':
                fields.push({
                  key: i18n.t('pre-records.fields.stage'),
                  value: this.getByProperty(
                    this.filtersObjects.storage_device_types_ids,
                    'storage_type_name'
                  ).join(', '),
                })
                return
            }
          }
        })

        // Filters Values
        filters.filterValues = fields
        // Storage Types
        filters.storage_device_types_ids = this.getByProperty(
          this.filtersObjects.storage_device_types_ids,
          'storage_device_type_id'
        )
        // Dates
        filters.start_record_date_time = this.date.filtersRecordDate.length > 0
          ? [
            moment(this.date.filtersRecordDate[0]).format(
              'YYYY-MM-DD'
            ) + 'T00:00:01',
            moment(this.date.filtersRecordDate[1]).format(
              'YYYY-MM-DD'
            ) + 'T23:59:59',
          ]
          : []
        // Seasons
        filters.seasons_ids = this.filtersObjects.seasons_ids &&
        this.filtersObjects.seasons_ids.length > 0
          ? this.getByProperty(
            this.filtersObjects.seasons_ids,
            'season_name'
          )
          : []
        // Origin
        filters.sdi_origin_ids = this.filtersObjects.sdi_origin_ids &&
        this.filtersObjects.sdi_origin_ids.length > 0
          ? this.filtersObjects.sdi_origin_ids
          : []
        // Destination
        filters.sdi_destination_ids = this.filtersObjects.sdi_destination_ids &&
        this.filtersObjects.sdi_destination_ids.length > 0
          ? this.filtersObjects.sdi_destination_ids
          : []
        // Person in Charge
        filters.person_in_charge_ids = this.filtersObjects.person_in_charge_ids &&
        this.filtersObjects.person_in_charge_ids.length > 0
          ? this.getByProperty(
            this.filtersObjects.person_in_charge_ids,
            'id'
          )
          : []
        // Process
        filters.process_ids = this.filtersObjects.process_ids &&
        this.filtersObjects.process_ids.length > 0
          ? this.getByProperty(
            this.filtersObjects.process_ids,
            'id'
          )
          : []
        filters.language = LanguajeService.getLenguajeName()
        filters.withEvidences = this.evidencePresent
        filters.withDocuments = this.documentPresent
        filters.withExternalVisibility = true
        filters.orientation = this.orientation
        filters.pageableDTO = this.filters.pageableDTO
        switch (this.currentReport.type) {
          case "rastreability":
            filters.type = 'traceability'
            break
          case "recall":
            filters.type = 'recall'
            break
        }
        filters.currentDate = moment(new Date())
          .format()
          .slice(0, 19)

        await this.exportRecordsAction([
          filters,
          null,
          'pdfTraceability',
          this.$toast,
        ]).finally(() => {
          this.exportLoading = false
          this.dialogClose()
        })
        this.selectedItems = []
      },

      getDateFormat(dateString) {
        if (dateString)
          return moment(new Date(dateString)).format(this.date.dateFormat)

        return null;
      },
      dateRangeText() {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (this.filtersDate.length > 1) {
          if (this.filtersDate[0]) {
            ;[year, month, day] = this.filtersDate[0].split('-')
          }
          if (this.filtersDate[1]) {
            ;[year2, month2, day2] = this.filtersDate[1].split('-')
          }
          this.period = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },
      clearProductData() {
        this.checkboxState.products = false
        this.product = null
        // this.$store.commit('reports/SET_RECORDS_BY_IDS_ORIGINAL_STATE')
        localStorage.removeItem('recordIds')
        localStorage.removeItem('documentIds')
        this.filterdsRecords = []
      },
      async filteredByLote() {
        this.options.sortDesc[0] = 'desc'
        this.filterdsRecords = []
        this.filterdsRecordsBackUp = []
        this.filterdsRecordsIdChecked = []
        if ((this.lote !== '' && this.lote !== null) && (this.product !== '' && this.product !== null)) {
          this.searchRecords = true

          this.filters.stringFilter = this.filtersObjects.stringFilter;
          this.filters.company_id = this.profile.company_id
          this.filters.lots =
            this.lote && this.lote.length > 0 ? [this.lote] : []
          this.filters.products_ids =
            this.product && this.product.length > 0 ? [this.product] : []

          const ids = JSON.parse(localStorage.getItem('recordIds'));

          if (ids && ids.length > 0) {
            this.filters.record_ids = ids
          } else {
            this.filters.record_ids = []
          }

          this.backupPageable()

          const keys = [
            "end_record_date_time",
            "isActive",
            "isActiveOnMobile",
            "isExpired",
            "language",
            "lots",
            "person_in_charge_ids",
            "process_ids",
            "products_ids",
            "sdi_destination_ids",
            "sdi_origin_ids",
            "seasons_ids",
            "sellers_ids",
            "start_record_date_time",
            "storage_device_types_ids",
            "pageableDTO",
            "structure",
            "withExternalVisibility",
            "reportType",
            "record_ids",
            "status",
            "stringFilter"
          ]
          let filters = _.pick(this.filters, keys)

          switch (this.currentReport.type) {
            case "rastreability":
              filters.pageableDTO.direction = 'desc'
              filters.reportType = 'TRACEABILITY'
              break
            case "recall":
              filters.pageableDTO.direction = 'asc'
              filters.reportType = 'RECALL'
              break
          }
          filters.language = LanguajeService.getLenguajeName()
          filters.withExternalVisibility = true

          await this.$store.dispatch('authority/loadConfigUser', [this.$toast])

          //------------------------------------------
          const config = JSON.parse(localStorage.getItem('config'))

          console.log(config)

          if (config) {
            this.config.headers = _.cloneDeep(this.defaultHeaders)

            console.log(this.config.headers)

            if (config.structures.simples && config.structures.simples.processed.length > 0) {
              _.forEach(config.structures.simples.processed, header => {
                this.config.headers.splice(
                  this.config.headers.length - 1,
                  0,
                  header
                )
              })

              this.headers = _.cloneDeep(this.config.headers)
            } else {
              this.headers = _.cloneDeep(this.defaultHeaders)
            }
          } else {
            this.headers = _.cloneDeep(this.defaultHeaders)
          }
          //------------------------------------------

          const { data } = await this.fetchExistingTraceabilityReportData([this.$toast, filters])

          if(data.isPresent) {
            this.ifExistFilters = _.clone(filters)
            this.existingReport = data.report
            this.isExist = true
          } else {
            this.ifExistFilters = {
              ...filters,
              generateNew: true
            }
          }

          await this.appointmentsByLote([this.$toast, this.ifExistFilters, this.currentReport.type])

          await this.fetchTraceabilityById().finally(() => {
            this.$root.$emit('updateOptions', null)
          });
        }
      },
      async ifExistAction(action) {
        this.searchRecords = true

        switch (this.currentReport.type) {
          case "rastreability":
            this.ifExistFilters.reportType = 'TRACEABILITY'
            break
          case "recall":
            this.ifExistFilters.reportType = 'RECALL'
            break
        }

        switch (action) {
          case "VIEW":
            this.isExist = false

            this.ifExistFilters = {
              ...this.ifExistFilters,
              generateNew: false
            }

            await this.appointmentsByLote([this.$toast, this.ifExistFilters, this.currentReport.type])

            await this.fetchTraceabilityById().finally(() => {
              this.searchRecords = false
            })
            break;
          case "CREATE":
            this.isExist = false
            this.ifExistFilters = {
              ...this.ifExistFilters,
              generateNew: true
            }

            await this.appointmentsByLote([this.$toast, this.ifExistFilters, this.currentReport.type])

            await this.fetchTraceabilityById().finally(() => {
              this.searchRecords = false
            })
            break;
        }
      },
      backupPageable() {
        if (this.filters.pageableDTO) {
          this.pageableDTO = _.cloneDeep(this.filters.pageableDTO)
        }
        else {
          this.filters.pageableDTO = _.cloneDeep(this.pageableDTO)
        }
      },

      async fetchTraceabilityById(page) {
        if (page) {
          this.options.page = page.no
          this.optionsTable = this.options
        }
        const ids = JSON.parse(localStorage.getItem('recordIds'))

        this.backupPageable()

        if (ids && ids.length > 0) {
          const keys = [
            "end_record_date_time",
            "sent_date_time",
            "isActive",
            "isActiveOnMobile",
            "isExpired",
            "language",
            "person_in_charge_ids",
            "process_ids",
            "record_ids",
            "sdi_destination_ids",
            "sdi_origin_ids",
            "seasons_ids",
            "sellers_ids",
            "pageableDTO",
            "start_record_date_time",
            "storage_device_types_ids",
            "structure",
            "withExternalVisibility",
            "products_ids",
            "lots",
            "generateNew",
            "reportType",
            "status",
            "stringFilter"
          ]

          let filters2 = null
          if (this.ifExistFilters) {
            filters2 = _.pick(this.ifExistFilters, keys)
            _.mergeWith(filters2, this.filters, (objValue, srcValue) => {
              if (srcValue !== undefined) {
                return srcValue;
              }
              return objValue;
            });

            filters2 = _.pick(filters2, keys);
          } else {
            filters2 = _.pick(this.filters, keys)
          }

          filters2.record_ids = ids
          if (!filters2.reportType) filters2.reportType = this.ifExistFilters.reportType
          filters2.pageableDTO = this.filters.pageableDTO

          await this.fetchTraceabilityReportData([this.$toast, filters2])
            .then(() => {
              this.filterdsRecords = _.map(this.traceability, item => {
                return {
                  ...item,
                  sdi_origin_name:
                    item.sdi_origin && item.sdi_origin.control_label
                      ? item.sdi_origin.primaryLocation.name +
                      ' - ' +
                      item.sdi_origin.control_label
                      : 'NaN',
                  sdi_destination_name:
                    item.sdi_destination && item.sdi_destination.control_label
                      ? item.sdi_destination.primaryLocation.name +
                      ' - ' +
                      item.sdi_destination.control_label
                      : 'NaN',
                }
              })
              this.filterdsRecordsBackUp = Object.assign(
                [],
                this.filterdsRecords
              )
            }).finally(() => {
              this.searchRecords = false
            })
        }
      },
      replace(event) {
        this.lote = event.currentTarget.value.toString().replaceAll('"', '')
      },
    },
    destroyed() {
      this.request = false
      this.message = i18n.t('general.notification.loadData')
      if (this.source) {
        this.source.cancel('cancelRequest')
        this.source = null
      }

      this.$store.commit('general/SET_ORIGINAL_STATE_CONFIG')
      this.$store.commit('reports/SET_RECORDS_BY_IDS_ORIGINAL_STATE')
      this.$store.commit('ui/FILTERS_ORIGINAL_STATE');
      this.$store.commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE');
      this.$root.$off('setFiltersTraceability')
      this.$root.$off('getResults')

      localStorage.removeItem('recordIds')
      localStorage.removeItem('documentIds')
    },
  }
</script>

<style scoped>
  .v-data-table {
    margin: 25px;
  }

  #change p {
    width: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
  }

  #change:hover p {
    width: 130px;
    max-width: 100%;
    overflow-x: hidden;
  }

  #change:hover i {
    transform: rotate(200grad);
  }

  .table__head {
    height: 5vh;
    background-color: #f7f7f7;
  }
  :deep(.v-chip-group .v-slide-group__content) {
    display: flex !important;
    justify-content: flex-start !important;
  }
  :deep(.v-toolbar__content) {
    padding: 0 !important;
  }
</style>
